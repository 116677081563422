<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row>
				<a-col :span="12">
					<a-month-picker
						v-model="attCycle"
						@change="onAttCycleChange"
						:allowClear="false"
						style="width: 150px"
					/>
				</a-col>
				<a-col :span="12" style="text-align: right">
					<a-input-search
						name="filterText"
						style="width: 200px"
						:placeholder="l('SearchWithThreeDot')"
						@search="getData"
						enterButton
						v-model="filterText"
					/>
				</a-col>
			</a-row>
			<a-row>
				<a-col>
					<a-col :span="24" style="margin-top: 10px;" v-if="attCycleStartTime && attCycleEndTime"
						>考勤周期: {{ attCycleStartTime }}~{{ attCycleEndTime }}</a-col
					>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				@change="sorterChange"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				:dataSource="tableData"
			>
				<p class="subscriptionEndUtc" slot="subscriptionEndUtc" slot-scope="record">{{ record }}</p>
				<a class="isActive" slot="isActive" slot-scope="record">
					<a-icon v-if="record" type="check" />
					<a-icon v-if="!record" type="close" />
				</a>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import moment from 'moment';
import { EnterpriseAttendCycleServiceProxy, OverTimeRecordServiceProxy } from '../../../shared/service-proxies';

export default {
	name: 'excess-max-overtime-record',
	mixins: [AppComponentBase],
	data() {
		return {
			//考勤周期
			attCycle: moment(),
			attCycleStartTime: '',
			attCycleEndTime: '',
			// 表格
			columns: [
				{
					title: this.l('工号'),
					dataIndex: 'jobNumber',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'jobNumber' },
				},
				{
					title: this.l('姓名'),
					dataIndex: 'realName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: this.l('公司'),
					dataIndex: 'companyName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'companyName' },
				},
				{
					title: this.l('部门'),
					dataIndex: 'departmentName',
					align: 'center',
					sorter: true,
					ellipsis: true,
					scopedSlots: { customRender: 'departmentName' },
				},
				{
					title: this.l('加班合计/时'),
					dataIndex: 'overTimeCount',
					align: 'center',
					sorter: true,
					ellipsis: true,
					width: 200,
					scopedSlots: { customRender: 'overTimeCount' },
				},
			],

			tableData: [],
		};
	},
	async mounted() {
		this._enterpriseAttendCycleServiceProxy = new EnterpriseAttendCycleServiceProxy(this.$apiUrl, this.$api);
		this._overTimeRecordServiceProxy = new OverTimeRecordServiceProxy(this.$apiUrl, this.$api);
		try {
			this.loading = true;
			await this.getCycle('init');
		} catch (e) {
			console.log(e);
		} finally {
			this.loading = false;
		}
		this.getData();
	},
	methods: {
		getData() {
			this.loading = true;
			this._overTimeRecordServiceProxy
				.getExcessdMaxOverTimeNumberList(
					this.attCycleStartTime ? moment(this.attCycleStartTime) : undefined,
					this.attCycleEndTime ? moment(this.attCycleEndTime) : undefined,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.totalItems = res.totalCount;
				});
		},
		async getCycle(type) {
			if (type === 'init') {
				const { startTime, endTime, yearOrMonth } = await this.initCycle();
				this.attCycleStartTime = startTime;
				this.attCycleEndTime = endTime;
				this.attCycle = moment(yearOrMonth);
			} else {
				const { startTime, endTime } = await this.getCycleByDate();
				this.attCycleStartTime = startTime;
				this.attCycleEndTime = endTime;
			}
		},
		/**
		 * 初始化考勤周期
		 */
		async initCycle() {
			return new Promise((resolve, reject) => {
				this._enterpriseAttendCycleServiceProxy.getCycleByNow().then((res) => {
					resolve(res);
				});
			});
		},
		/**
		 * 获取指定年月的周期
		 */
		async getCycleByDate() {
			return new Promise((resolve, reject) => {
				this._enterpriseAttendCycleServiceProxy
					.getByTenant(this.attCycle.year(), this.attCycle.month() + 1)
					.then((res) => {
						resolve(res);
					});
			});
		},
		//考勤周期选择
		async onAttCycleChange(e) {
			this.attCycle = e;
			await this.getCycle();
			this.getData();
		},

		/**
		 * 排序
		 * @param pagination
		 * @param filters
		 * @param sorter
		 */
		sorterChange(pagination, filters, sorter) {
			if (sorter.order) {
				this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
			} else {
				this.request.sorting = undefined;
			}
			this.getData();
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
